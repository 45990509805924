// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://logistqonaev.kz:3001',
    
    // nameCargo: 'TURKESTAN KARGO'
    nameCargo: 'LOGIST_Cargo_Qonaev'
  };
  
  
export default config;
  